import { createSlice } from '@reduxjs/toolkit';
import { doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../funtion/Firebaseconfig';

// Hàm lấy session ID từ localStorage
const getSessionId = () => {
    let sessionId = localStorage.getItem('sessionId');
    if (!sessionId) {
        sessionId = `session-${Math.random().toString(36).substr(2, 9)}`; // Tạo ID ngẫu nhiên nếu chưa có
        localStorage.setItem('sessionId', sessionId); // Lưu vào localStorage
    }
    return sessionId;
};

// Slice để quản lý dữ liệu phân tích
const analyticsSlice = createSlice({
    name: 'analytics',
    initialState: {
        onlineUsers: [],
        pageViews: {
            mainPage: 0,
            tourPage: 0,
            blogPage: 0,
        },
        totalVisits: 0,
    },
    reducers: {
        updateAnalytics: (state, action) => {
            state.onlineUsers = action.payload.onlineUsers;
            state.pageViews = action.payload.pageViews;
            state.totalVisits = action.payload.totalVisits;
        },
    },
});

export const { updateAnalytics } = analyticsSlice.actions;

// Fetch dữ liệu phân tích từ Firestore
export const fetchAnalyticsData = () => async (dispatch) => {
    try {
        const analyticsRef = doc(db, 'analytics', 'data');
        const analyticsSnapshot = await getDoc(analyticsRef);

        if (analyticsSnapshot.exists()) {
            const analyticsData = analyticsSnapshot.data();
            console.log("Fetched analytics data from Firestore:", analyticsData); // Debug log
            dispatch(updateAnalytics(analyticsData));
        } else {
            await setDoc(analyticsRef, {
                onlineUsers: [],
                pageViews: {
                    mainPage: 0,
                    tourPage: 0,
                    blogPage: 0,
                },
                totalVisits: 0,
            });
        }
    } catch (error) {
        console.error('Error fetching analytics data:', error);
    }
};

// Cập nhật dữ liệu khi người dùng truy cập trang mới
export const updateVisitData = (location) => async (dispatch) => {
    const sessionId = getSessionId(); // Sử dụng session ID duy nhất cho mỗi người dùng

    try {
        const analyticsRef = doc(db, 'analytics', 'data');
        const analyticsSnapshot = await getDoc(analyticsRef);

        if (analyticsSnapshot.exists()) {
            const analyticsData = analyticsSnapshot.data();
            let updatedPageViews = { ...analyticsData.pageViews };
            let updatedTotalVisits = analyticsData.totalVisits + 1;

            // Cập nhật danh sách người dùng online: thêm sessionId nếu chưa có
            let updatedOnlineUsers = [...new Set([...analyticsData.onlineUsers, sessionId])];

            switch (location.pathname) {
                case '/':
                    updatedPageViews.mainPage += 1;
                    break;
                case '/tourlist':
                    updatedPageViews.tourPage += 1;
                    break;
                case '/blog':
                    updatedPageViews.blogPage += 1;
                    break;
                default:
                    break;
            }

            // Cập nhật Firestore với dữ liệu mới
            await updateDoc(analyticsRef, {
                onlineUsers: updatedOnlineUsers,
                pageViews: updatedPageViews,
                totalVisits: updatedTotalVisits,
            });

            console.log("Updated analytics data:", {
                onlineUsers: updatedOnlineUsers,
                pageViews: updatedPageViews,
                totalVisits: updatedTotalVisits,
            });

            dispatch(updateAnalytics({
                onlineUsers: updatedOnlineUsers,
                pageViews: updatedPageViews,
                totalVisits: updatedTotalVisits,
            }));
        }
    } catch (error) {
        console.error('Lỗi khi cập nhật dữ liệu phân tích:', error);
    }
};

export default analyticsSlice.reducer;
