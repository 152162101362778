import React, { useEffect, useState } from 'react';
import { db, storage } from '../funtion/Firebaseconfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore'; // Firestore methods
import { ref, deleteObject, uploadBytes, getDownloadURL } from 'firebase/storage'; // Storage methods
import Styles from './CustomBackground.module.css';

function CustomBackground() {
    const [backgroundUrl, setBackgroundUrl] = useState(''); // URL của background hiện tại
    const [newImageFile, setNewImageFile] = useState(null); // File ảnh mới do user chọn
    const [isLoading, setIsLoading] = useState(false); // State để quản lý trạng thái tải

    useEffect(() => {
        const fetchBackground = async () => {
            try {
                console.log('Fetching background data...');
                const docRef = doc(db, 'background', 'custombackground');
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    console.log('Document data:', data);

                    if (data.img) {
                        setBackgroundUrl(data.img);
                    } else {
                        console.warn('Field "img" is missing in document: custombackground');
                    }
                } else {
                    console.warn('No document found with ID: custombackground');
                }
            } catch (error) {
                console.error('Error fetching background:', error);
            }
        };

        fetchBackground();
    }, []);

    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setNewImageFile(e.target.files[0]);
        }
    };

    const handleUpload = async () => {
        if (!newImageFile) {
            alert('Please select an image to upload.');
            return;
        }

        setIsLoading(true); // Bật trạng thái loading

        try {
            const docRef = doc(db, 'background', 'custombackground');
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const oldImgUrl = docSnap.data().img;

                // Xóa ảnh cũ khỏi Firebase Storage
                if (oldImgUrl) {
                    const oldImgRef = ref(storage, decodeURIComponent(oldImgUrl.split('%2F').pop().split('?')[0]));
                    await deleteObject(oldImgRef);
                    console.log('Old image deleted:', oldImgUrl);
                }
            }

            // Tải ảnh mới lên
            const newImgRef = ref(storage, `backgrounds/${newImageFile.name}`);
            await uploadBytes(newImgRef, newImageFile);
            const newImgUrl = await getDownloadURL(newImgRef);
            console.log('New image uploaded:', newImgUrl);

            // Cập nhật Firestore với URL của ảnh mới
            await updateDoc(docRef, { img: newImgUrl });
            console.log('Firestore updated with new image URL.');

            // Cập nhật lại state backgroundUrl và tắt trạng thái loading
            setBackgroundUrl(newImgUrl);
            setIsLoading(false);
            alert('Background updated successfully!');
        } catch (error) {
            console.error('Error updating background:', error);
            setIsLoading(false);
        }
    };

    return (
        <div className={Styles.customBackground}>
            <div
                style={{
                    backgroundImage: backgroundUrl ? `url(${backgroundUrl})` : 'none',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '100vh',
                }}
            >
                {!backgroundUrl && !isLoading && (
                    <p style={{ color: 'white', textAlign: 'center' }}>
                        Loading background...
                    </p>
                )}
                {isLoading && (
                    <p style={{ color: 'white', textAlign: 'center' }}>
                        Uploading new background...
                    </p>
                )}
            </div>

            <div style={{ padding: '20px', textAlign: 'center' }}>
                <input type="file" accept="image/*" onChange={handleImageChange} />
                <button onClick={handleUpload} style={{ marginLeft: '10px' }}>
                    Upload New Background
                </button>
            </div>
        </div>
    );
}

export default CustomBackground;
