import React, { useEffect, useState, Suspense, lazy } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { checkLoginState } from './components/funtion/AuthSlice';
import { fetchAnalyticsData, updateVisitData } from './components/admin/AnalyticsSlice';
import Header from "./components/header/Header";
import Body from "./components/body/Body";
import Footer from "./components/footer/Footer";
import LoadingPage from "./components/common/Loadingpage";
import Navbar from "./components/header/Navbar";
import LoginModal from "./components/common/LoginModal";
import CustomBackground from './components/common/CustomBackground';
import { db } from "./components/funtion/Firebaseconfig";
import 'bootstrap/dist/css/bootstrap.min.css';

const BlogContent = lazy(() => import("./components/body/BlogContent"));
const TourContent = lazy(() => import("./components/body/TourContent"));
const Gallery = lazy(() => import("./components/body/Gallery"));
const AdminPanel = lazy(() => import("./components/admin/AdminPanel"));
const AboutUs = lazy(() => import("./components/footer/AbouUs"));
const PaymentGuide = lazy(() => import("./components/footer/PaymentGuide"));
const BookingGuide = lazy(() => import("./components/footer/BookingGuide"));

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isAdmin = useSelector((state) => state.auth.isAdmin);

  useEffect(() => {
    dispatch(checkLoginState());
    dispatch(fetchAnalyticsData());

    const timer = setTimeout(() => setIsLoading(false), 1300);
    return () => clearTimeout(timer);
  }, [dispatch]);

  const isHomePage = location.pathname === '/';

  useEffect(() => {
    const userId = localStorage.getItem('userId') || 'anonymous';
    dispatch(updateVisitData(userId, location.pathname));
  }, [location.pathname, dispatch]);

  return (
    <>
      {isLoading && <LoadingPage />}
      {!isLoading && (
        <div>
          <CustomBackground />
          <Navbar isAdmin={isAdmin} isLoggedIn={isLoggedIn} />

          {isHomePage && <Header />}

          <Suspense fallback={<LoadingPage />}>
            <Routes>
              <Route path="/" element={<Body db={db} />} />
              <Route path="/tourlist" element={<TourContent db={db} />} />
              <Route path="/tourlist/:postId" element={<TourContent db={db} isLoggedIn={isLoggedIn} isAdmin={isAdmin} />} />
              <Route path="/blog" element={<BlogContent db={db} />} />
              <Route path="/blog/:postId" element={<BlogContent db={db} isLoggedIn={isLoggedIn} isAdmin={isAdmin} />} />
              <Route path="/gallery" element={<Gallery db={db} isLoggedIn={isLoggedIn} isAdmin={isAdmin} />} />
              <Route path="/aboutus" element={<AboutUs db={db} isLoggedIn={isLoggedIn} isAdmin={isAdmin} />} />
              <Route path="/paymentguide" element={<PaymentGuide db={db} isLoggedIn={isLoggedIn} isAdmin={isAdmin} />} />
              <Route path="/bookingguide" element={<BookingGuide db={db} isLoggedIn={isLoggedIn} isAdmin={isAdmin} />} />
              <Route path="/login" element={<LoginModal db={db} />} />
              <Route
                path="/admin"
                element={isLoggedIn && isAdmin ? <AdminPanel db={db} /> : <Navigate to="/login" />}
              />
            </Routes>
          </Suspense>
          <Footer id="footer" />
        </div>
      )}
    </>
  );
}

export default App;
