import React, { useEffect } from "react";
import BlogPosts from "./BlogPosts";
import Tourlist from "./Tourlist";
import SlideShow from "./Slideshow";
import { motion } from "framer-motion";
import styles from "./Body.module.css";
import { useDispatch } from 'react-redux';
import { updateVisitData } from '../admin/AnalyticsSlice';

function Body({ db }) {
  const dispatch = useDispatch();

  useEffect(() => {
    const userId = localStorage.getItem('userId') || 'anonymous';
    dispatch(updateVisitData(userId));
  }, [dispatch]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div id="blog" className={styles["blog-container"]}>
        <div id="home">
          <SlideShow db={db} />
        </div>
        <Tourlist db={db} />
        <div id="body">
          <BlogPosts db={db} />
        </div>
      </div>
    </motion.div>
  );
}

export default Body;
